/* eslint-disable camelcase */
import { useEffect } from 'react'
import { Router, useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { useWindowSize } from '@moneymade/moneymade-ui'

import { ROUTES } from 'static/menusConstants'
import {
  getArticlesCall,
  getAssetPagesCall,
  getBannersCall,
  getCollectionsCall,
  getHeaderPlatformCall,
  getInHeaderArticleCall,
  getInvestmentsCall,
  getPlatformReviewsCall
} from 'api/calls'

const InitApp = ({
  isAuthChecked,
  readHeader,
  pratformsHeader,
  handleServiceField,
  handleSetUrlFields,
  handleSetDiscoverField,
  handleSetAppField,
  handleSetFooterField
}) => {
  const { screenWidth } = useWindowSize()
  const { asPath, query } = useRouter()

  const isDashboard = asPath.includes(`/${ROUTES.dashboard}`)
  const isPortfolio = asPath === `/${ROUTES.portfolio}`

  // Parse URL and get GET params
  useEffect(() => {
    // To not do state update before we got url data
    if (asPath.includes('?') && !Object.values(query).length) return

    // Get URL params
    const { utm_campaign, utm_medium, utm_source, utm_content } = query

    // Add existing data to redux store
    handleSetUrlFields([
      ...(utm_campaign ? [{ key: 'itemCampaign', value: utm_campaign }] : []),
      ...(utm_medium ? [{ key: 'itemMedium', value: utm_medium }] : []),
      ...(utm_content ? [{ key: 'itemContent', value: utm_content }] : []),
      ...(utm_source ? [{ key: 'itemSource', value: utm_source }] : [])
    ])
  }, [asPath, handleSetUrlFields, query])

  // Redirect to requested route after the auth
  useEffect(() => {
    if (!isAuthChecked && isDashboard) window['private-router'] = asPath
    else if (isAuthChecked) window['private-router'] = null
  }, [isAuthChecked, isDashboard, asPath])

  // Router tracker
  useEffect(() => {
    // Create empty array if no route history
    if (!window?.routes) window.routes = [window.location.pathname]

    const trackRouter = url => {
      // Set prev route
      window.prevRoute = window.routes[0] || null
      // Add curren url to routes history
      window.routes.unshift(url)
    }

    // Track router
    Router.events.on('routeChangeStart', trackRouter)
    // Disable router tracking
    return () => Router.events.off('routeChangeStart', trackRouter)
  }, [])

  // Tidio chat
  useEffect(() => {
    /* const tidioScript = document.createElement('script')
    tidioScript.src = '//code.tidio.co/3vl6gjk1lftssxxquzcd2bqjxxrm0oma.js'
    document.body.appendChild(tidioScript) */

    const onTidioChatApiReady = () => {
      const chat = document.querySelector('#tidio-chat')

      if (chat && isPortfolio && screenWidth > 961) chat.style.display = 'block'
      else if (chat) chat.style.display = 'none'
    }

    // For on load
    if (window.tidioChatApi) window.tidioChatApi.on('ready', onTidioChatApiReady)
    else document.addEventListener('tidioChat-ready', onTidioChatApiReady)

    // For page change
    onTidioChatApiReady()
  }, [isPortfolio, screenWidth])

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' })
  // }, [screenWidth])
  useEffect(() => {
    const handlePlatformsCall = async () => {
      const investmentsPickFields = '?pick-fields=[*]name&pick-fields=[*]slug'

      const { success, result } = await getInvestmentsCall(investmentsPickFields)()

      if (success) {
        // Fixed first three elements
        const fixedOrder = [
          { name: 'Vinovest', slug: 'vinovest' },
          { name: 'Masterworks', slug: 'masterworks' },
          { name: 'Concreit', slug: 'concreit' }
        ]

        // Filter the array to exclude these three elements
        const remainingArray = result.filter(item => !fixedOrder.map(platform => platform.name).includes(item.name))
        // Select 7 random elements
        const randomPlatform = remainingArray.sort(() => 0.5 - Math.random()).slice(0, 7)
        // Create the final array
        const finalPlatforms = [...fixedOrder, ...randomPlatform]
        handleSetFooterField({ key: 'discover', value: finalPlatforms })
      }
    }
    handlePlatformsCall()
  }, [handleSetFooterField])

  useEffect(() => {
    const handleAssetsCall = async () => {
      const { success, result } = await getAssetPagesCall()()

      if (success) {
        // Fixed first three elements
        const fixedOrder = [
          { name: 'Gold', slug: 'gold' },
          { name: 'Art', slug: 'art' },
          { name: 'Wine', slug: 'wine' },
          { name: 'Bitcoin', slug: 'bitcoin' }
        ]

        // Filter the array to exclude these three elements
        const remainingArray = result.filter(item => !fixedOrder.map(platform => platform.name).includes(item.name))
        // Select 7 random elements
        const randomPlatform = remainingArray
          .sort(() => 0.5 - Math.random())
          .slice(0, 6)
          .map(item => {
            const formattedName = item
              .split('-') // Split the slug by hyphen
              .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
              .join(' ') // Join them back with a space

            return { name: formattedName, slug: item }
          })
        // Create the final array
        const finalPlatforms = [...fixedOrder, ...randomPlatform]
        handleSetFooterField({ key: 'trending', value: finalPlatforms })
      }
    }
    handleAssetsCall()
  }, [handleSetFooterField])

  useEffect(() => {
    const handleArticlesCall = async () => {
      const articlesPickFields = '&pick-fields=[*]slug&pick-fields=[*]title'

      const { success, result } = await getArticlesCall(articlesPickFields)()

      if (success) {
        // Select 7 random elements
        const randomArticle = result.sort(() => 0.5 - Math.random()).slice(0, 10)
        // Create the final array
        handleSetFooterField({ key: 'articles', value: randomArticle })
      }
    }
    handleArticlesCall()
  }, [handleSetFooterField])

  useEffect(() => {
    const handlePlatformReviewsCall = async () => {
      const platformPickFields = '&pick-fields=[*]slug&pick-fields=[*]name'

      const { success, result } = await getPlatformReviewsCall(platformPickFields)()

      if (success) {
        // Select 7 random elements
        const randomReview = result.sort(() => 0.5 - Math.random()).slice(0, 10)
        // Create the final array
        handleSetFooterField({ key: 'reviews', value: randomReview })
      }
    }
    handlePlatformReviewsCall()
  }, [handleSetFooterField])

  useEffect(() => {
    const handleCollectionsCall = async () => {
      const collectionsPickFields = '&pick-fields=[*]slug&pick-fields=[*]title'

      const { success, result } = await getCollectionsCall(collectionsPickFields)()

      if (success) {
        // Select 7 random elements
        const randomCollection = result.sort(() => 0.5 - Math.random()).slice(0, 10)
        // Create the final array
        handleSetFooterField({ key: 'collection', value: randomCollection })
      }
    }
    handleCollectionsCall()
  }, [handleSetFooterField])

  // Get Banners from API
  useEffect(() => {
    const handleCtaCall = async () => {
      const { success, result } = await getBannersCall()()

      if (success) handleSetAppField({ key: 'banners', value: result })
    }
    handleCtaCall()
  }, [handleSetAppField])

  // // Get articles for header from API
  useEffect(() => {
    if (!readHeader.length) {
      const handleHeaderArticleGet = async () => {
        const { success: successRead, result: resultRead } = await getInHeaderArticleCall()()

        // Update state with header items info
        if (successRead) handleServiceField({ key: 'readHeader', value: resultRead })
      }
      handleHeaderArticleGet()
    }
  }, [handleServiceField, readHeader.length])

  useEffect(() => {
    if (!pratformsHeader.length) {
      const handleHeaderPlatformGet = async () => {
        const { success: successPlatforms, result: resultPlatforms } = await getHeaderPlatformCall()()

        // Update state with header items info
        if (successPlatforms) handleServiceField({ key: 'pratformsHeader', value: resultPlatforms })
      }
      handleHeaderPlatformGet()
    }
  }, [handleServiceField, pratformsHeader.length])

  // Set visible platforms on discover page based on a device screen width
  useEffect(() => {
    handleSetDiscoverField({ key: 'platformsToShow', value: 999 })
  }, [handleSetDiscoverField, screenWidth])

  // If site is opened in iframe window. Now can be opened only in same domain
  useEffect(() => {
    if (window.location === window.parent.location) return

    handleSetAppField({ key: 'inIframe', value: true })
    // Send message to parent
    window.parent.postMessage({ isLoaded: true }, '*')
    // Add base element to open all links in new tab
    const baseElement = document.createElement('base')
    baseElement.target = '_blank'
    document.head.appendChild(baseElement)
  }, [handleSetAppField])

  return null
}

InitApp.propTypes = {
  readHeader: PropTypes.arrayOf(PropTypes.object),
  pratformsHeader: PropTypes.arrayOf(PropTypes.object),
  isAuthChecked: PropTypes.bool,
  handleSetUrlFields: PropTypes.func,
  handleSetDiscoverField: PropTypes.func,
  handleSetAppField: PropTypes.func,
  handleServiceField: PropTypes.func,
  handleSetFooterField: PropTypes.func
}

InitApp.defaultProps = {
  readHeader: [],
  pratformsHeader: [],
  isAuthChecked: false,
  handleServiceField: () => {},
  handleSetUrlFields: () => {},
  handleSetDiscoverField: () => {},
  handleSetAppField: () => {},
  handleSetFooterField: () => {}
}

export default InitApp
