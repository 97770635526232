import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setServiceFieldAction } from 'redux/services/actions'
import { setUrlFieldsAction } from 'redux/url/actions'
import { setHubFieldsAction } from 'redux/hub/actions'
import { setDiscoverFieldAction } from 'redux/discover/actions'
import { setAppFieldAction, setFooterFieldAction } from 'redux/app/actions'
import InitApp from './InitApp'

const InitAppContainer = props => {
  const {
    isAuthChecked,
    isLogged,
    userData: { token }
  } = useSelector(({ auth }) => auth)
  const { readHeader, pratformsHeader } = useSelector(({ services }) => services)

  const dispatch = useDispatch()

  const setServiceField = useCallback(payload => dispatch(setServiceFieldAction(payload)), [dispatch])
  const setUrlFields = useCallback(payload => dispatch(setUrlFieldsAction(payload)), [dispatch])
  const setHubFields = useCallback(payload => dispatch(setHubFieldsAction(payload)), [dispatch])
  const setDiscoverField = useCallback(payload => dispatch(setDiscoverFieldAction(payload)), [dispatch])
  const setAppField = useCallback(payload => dispatch(setAppFieldAction(payload)), [dispatch])
  const setFooterField = useCallback(payload => dispatch(setFooterFieldAction(payload)), [dispatch])

  const updatedProps = {
    ...props,
    isAuthChecked,
    isLogged,
    token,
    readHeader,
    pratformsHeader,
    handleServiceField: setServiceField,
    handleSetUrlFields: setUrlFields,
    handleSetHubFields: setHubFields,
    handleSetDiscoverField: setDiscoverField,
    handleSetAppField: setAppField,
    handleSetFooterField: setFooterField
  }

  return <InitApp {...updatedProps} />
}

export default InitAppContainer
