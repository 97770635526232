import { SET_SERVICE_FIELD, SET_SERVICE_FIELDS, SET_SERVICE_NEWS_PAGE, SET_SERVICE_NEWS_ITEMS } from './types'

const setServiceFieldAction = payload => ({
  type: SET_SERVICE_FIELD,
  payload
})

const setServiceFieldsAction = payload => ({
  type: SET_SERVICE_FIELDS,
  payload
})

const setServiceNewsPageAction = payload => ({
  type: SET_SERVICE_NEWS_PAGE,
  payload
})

const setServiceNewsItemsAction = payload => ({
  type: SET_SERVICE_NEWS_ITEMS,
  payload
})

export { setServiceFieldAction, setServiceFieldsAction, setServiceNewsPageAction, setServiceNewsItemsAction }
