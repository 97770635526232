import { SET_URL_FIELD, SET_URL_FIELDS } from './types'

const setUrlFieldAction = payload => ({
  type: SET_URL_FIELD,
  payload
})

const setUrlFieldsAction = payload => ({
  type: SET_URL_FIELDS,
  payload
})

export { setUrlFieldAction, setUrlFieldsAction }
