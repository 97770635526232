import {
  SET_DISCOVER_FIELD,
  SET_DISCOVER_FIELDS,
  SET_FILTER_OPTION,
  SET_FILTER_CONTAINS_OPTION,
  RESET_ONE_FILTER,
  RESET_SIEVE
} from './types'

const setDiscoverFieldAction = payload => ({
  type: SET_DISCOVER_FIELD,
  payload
})

const setDiscoverFieldsAction = payload => ({
  type: SET_DISCOVER_FIELDS,
  payload
})

const setFilterOptionAction = payload => ({
  type: SET_FILTER_OPTION,
  payload
})

const setFilterContainsOptionAction = payload => ({
  type: SET_FILTER_CONTAINS_OPTION,
  payload
})

const resetOneFilterAction = payload => ({
  type: RESET_ONE_FILTER,
  payload
})

const resetSieveAction = () => ({
  type: RESET_SIEVE
})

export {
  setDiscoverFieldAction,
  setDiscoverFieldsAction,
  setFilterOptionAction,
  setFilterContainsOptionAction,
  resetOneFilterAction,
  resetSieveAction
}
